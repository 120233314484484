import React, { Fragment, lazy, Suspense, useRef } from "react"; // 
import useTheme from "@mui/system/useTheme";
import Box from "@mui/system/Box";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { alpha } from "@mui/material";

import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";

import IconExternalLink from "../../_shared/iconExternalLink"
import BowListLazy from "../../_shared/shaping/BowListLazy"
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import WavedFrameAnimationForChildren from "../../_shared/WavedFrameAnimationForChildren";

import SeparatorAbsSvg2Lazy from "../../_shared/shaping/SeparatorAbsSvg2Lazy";
import SvgInParentPaddingsLazy from "../../_shared/svgInParentPaddings/SvgInParentPaddingsLazy";
import { CHARACTER_A, CHARACTER_B, POLYGON_RECTANGLE_10_POINTS_LEFT_Units, CURVE_ELLIPSE_20_80_hard_asym, CURVE_ELLIPSE_20_80_hard_asym_b, CURVE_FULL_ELLIPSE_40, CURVE_ELLIPSE_80_20, CURVE_ELLIPSE_20_80_hard, CURVE_ELLIPSE_20_80, CURVE_RIGHT_HALF_ELLIPSE_20, CURVE_LEFT_HALF_ELLIPSE_80, CURVE_FULL_ELLIPSE, CURVE_ELLIPSE, CURVE_RIGHT_FULL, CURVE_LEFT_FULL, POLYGON_10, POLYGON_10_RIGHT_FULL, POLYGON_10_LEFT_FULL, POLYGON_STAR, POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, INSET_FULL, INSET_1_3 } from "../../../utils/clipPathsConstants";
import ButtonForExternalLink from "../../_shared/ButtonForExternalLink";
import ReactVsWordpressLazy from "./ReactVsWordpressLazy";
import NeededFromOwnerForWebPagesLazy from "./NeededFromOwnerForWebPagesLazy";
import FrameMaskForDiv from "../../_shared/FrameMaskForDiv";
import SeparatorFromLeftHalfAndFromRightHalfLazy from "../../_shared/SeparatorFromLeftHalfAndFromRightHalfLazy"
import hello_b_svgPathsD from "../../../utils/helloSvg_b2_1000x500"

const LazyImageWithMaskAnimation = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'ImageWithMaskAnimationLazy' */ `../../_shared/ImageWithMaskAnimation`)
);
const LazyImageWithClipPathAnimations = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'ImageWithClipPathAnimationsLazy' */ `../../_shared/ImageWithClipPathAnimations`)
);
const LazyImageWithCircleAndCirclesAroundClipPathAnimation = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'ImageWithCircleAndCirclesAroundClipPathAnimationLazy' */ `../../_shared/ImageWithCircleAndCirclesAroundClipPathAnimation`)
);

const LazyRouletteWheel = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'RouletteWheelLazy' */ `../../_shared/MyRouletteWheel1`)
);
const LazyCirclesMotionX4WithCssWebsites = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'CirclesMotionX4WithCssWebsitesLazy' */ `../../_shared/CirclesMotionX4WithCssWebsites`)
);
const LazyCirclesMotionX4PopularWebsites = lazy(() =>
  import(/* webpackMode: "lazy", webpackChunkName: 'CirclesMotionX4PopularWebsitesLazy' */ `../../_shared/CirclesMotionX4PopularWebsites`)
);

const LazyReactForVisitors = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyReactForVisitors' */ './ReactForVisitors'));
const LazyReactForOwner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyReactForOwner' */ './ReactForOwner'));
const LazyIntroTextWordpressProblemCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyIntroTextWordpressProblemCCh' */ './IntroTextWordpressProblemCCh'));

const srcImgWeb1 = "/sf/web/Default_creating_web_pages_decomposed_with_smartphone_PC_lapto_3_400x267.jpg"
const srcImgWeb2 = "/sf/web/Default_creating_web_pages_decomposed_with_smartphone_PC_lapto_3_400x267.png"
const srcImgWeb3 = "/sf/web/designing_with_finger_394x605.jpg"
const srcImgWeb4 = "/sf/web/Default_elegant_multiple_variations_of_structured_detailed_web_929x471.jpg"

const hello_b_svgPathsDReversed = hello_b_svgPathsD.reverse()


const styleSxWrap = {
  display: 'block',
  "& .container": {
    px: [ 0.5, 1, 2, 3 ],
    py: [ 1, 3, 4, 5 ],
    my: 0,
    "&.contUp": {
      // background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
      // background: (theme) => theme.gradients.lin3(0, theme.palette.error.main, "0%", theme.palette.error.light, "50%", theme.palette.error.main, "100%",),
      color: "primary.contrastText",
      pb: 0,
      py: 0,
    },
    "& .contFeat": {
      position: "relative",
      my: 0,
      bgcolor: "primary.main",
      color: "primary.contrastText",
      py: [ 2, 4, 6, 8 ],
      px: [ 0.5, 1, 2, 3 ],
      "& .contVitSep": {
        my: 0,
        "& .paper.MuiPaper-elevation1": {
          position: "relative",
          padding: 4,
          textAlign: 'center',
          borderTopRightRadius: "25%",
          borderBottomLeftRadius: "25%",
          "& .MuiPaper-elevation1": {
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          },
          "& .gridItem": {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            "& .inGridItem": {
            },
            "& .wrapLink": {
              borderRadius: "255px 15px 225px 15px/15px 225px 15px 255px",
              borderWidth: "2px",
              borderColor: "error.dark",
              borderStyle: "solid",
              padding: "4px",
              width: "90%",
              ml: "auto",
              mr: "auto",
              '&:hover .MuiTypography-h6, &:hover .MuiSvgIcon-root, &:focus .MuiTypography-h6,&:focus .MuiSvgIcon-root': {
                // color: lighten("primary.contrastText", 1),
                color: "primary.contrastText",
                bgcolor: "primary.dark",
                // backgroundColor: emphasize(theme.palette.primary.dark, -0.12),
                textDecoration: "none",
              },
              "& .link": {
                textAlign: "center",

                "& .linkText": {
                  bgcolor: "secondary.dark",
                  color: "secondary.contrastText",
                  "&.MuiTypography-h2": {
                    position: "relative",
                    display: "inline-block",
                    boxShadow: 19,
                    fontSize: [ "1.5rem", "1.8rem", "2.5rem" ],

                    "&::before": {
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,

                      bgcolor: "primary.main",
                      opacity: 0.7,
                      content: '""',
                      clipPath: "circle(0% at 50% 50% )",
                    },
                    "&:hover": {
                      "&::before": {
                        content: '""',
                        // clipPath: "circle(10% at 50% 50% )",
                        clipPath: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
                      }
                    },
                    "&:active ::before": {
                      content: '""',
                      clipPath: "circle(100% at 50% 50% )",
                    },
                  }
                },
              },
            },
            wrapImgUnderLink: {
              opacity: 0,
              marginTop: 4,
              marginBottom: 4,
            },
          },
        },
      },
    },

    "& .wrapTitleBoost": {
      width: 1,
      "& .itemBL": {
        bgcolor: "secondary.dark",
        color: "primary.contrastText",
      },
    },
    "& .gridMain": {
      mx: 0,
      "& .extLink": {
        color: "unset",
      },
      "& .columnLeft": {
        ml: 0,
      },
      "& .columnRight": {
        mr: 0,
      },
    },
    "& .paper": {
      p: 2,
      textAlign: 'center',
      "& .extLink": {
        color: "unset",
        boxShadow: 11,
        "& svg": {
          color: "info.main",
        }
      },
    },
  }
}

const bLOptionsSingle = {
  listHeightPx: "auto",
  primarySecondarySeparator: "|",
}
const bLOptionsDouble = {
  listHeightPx: "auto",
  primarySecondarySeparator: "|",
}

const bLOptionsL = {
  bowDirection: "left",
  motionDirection: "left",
}
const bLOptionsMidL = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingTopPx: 0,
}

const bLOptionsMidR = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingTopPx: 0,
}
const bLOptionsUpL = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingBottomPx: 0,
}
const bLOptionsUpR = {
  bowDirection: "left",
  motionDirection: "left",
  listPaddingBottomPx: 0,
}
const styleBLBordersBLTR50px = {
  borderBottomLeftRadius: "50px",
  borderTopRightRadius: "50px",
}

const styleBLBordersBR20 = {
  borderBottomRightRadius: "20%",
}

const styleBLBordersBL20 = {
  borderBottomLeftRadius: "20%",
}

const sxTypography = {
  fontSize: [ "smaller", "small" ],

}

function MainUp(props) {

  const theme = useTheme()
  const bgPrimaryDark = theme.palette.primary2.dark

  const breakpoint = useBreakpoint()
  const refOverflow = useRef(null)
  const isNotMobile = [ "md", "lg", "xl" ].includes(breakpoint)

  const optionsWavedFrameAnimationForChildren = {
    framePaddings: { paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 20 },
    optionsFrames: {
      top: {
        createPaddings: false,
        styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
      },
      right: {
        createPaddings: false,
        styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
      },
      left: {
        createPaddings: false,
        styles: [ { fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ],
      },
      bottom: {
        createPaddings: false,
        styles: [ { fill: "green" }, { fill: 'lime' } ],
      },
    }
  }

  let fontSizeSingle
  let fontSizeDouble
  const listPaddingTopPx = 0
  const listPaddingBottomPx = 0
  const listMarginTopPx = 0
  const listMarginBottomPx = 20
  switch (breakpoint) {
    case "xs":
      bLOptionsSingle.itemHeightPx = 70 // 90
      bLOptionsSingle.itemsXDistancePx = 20
      fontSizeSingle = "small"
      fontSizeDouble = "small"
      bLOptionsDouble.itemHeightPx = 70 // 90
      bLOptionsDouble.itemsXDistancePx = 20
      break;
    case "sm":
      bLOptionsSingle.itemHeightPx = 60 // 70
      bLOptionsSingle.itemsXDistancePx = 80
      fontSizeSingle = "small"
      fontSizeDouble = "small"
      bLOptionsDouble.itemHeightPx = 50 // 60
      bLOptionsDouble.itemsXDistancePx = 40
      break;
    case "md":
      bLOptionsSingle.itemHeightPx = 40
      bLOptionsSingle.itemsXDistancePx = 40
      fontSizeSingle = "small"
      fontSizeDouble = "small"
      bLOptionsDouble.itemHeightPx = 60
      bLOptionsDouble.itemsXDistancePx = 20
      break;

    default:
      bLOptionsSingle.itemHeightPx = 40
      bLOptionsSingle.itemsXDistancePx = 40
      fontSizeSingle = "small"
      fontSizeDouble = "small"
      bLOptionsDouble.itemHeightPx = 60
      bLOptionsDouble.itemsXDistancePx = 20
      break;
  }

  const styleBLPaperPri = {
    backgroundColor: theme.colorMode === "dark" ? theme.palette.primary2.dark : theme.palette.primary2.light,
  }

  const styleBLPaperSec = {
    backgroundColor: theme.colorMode === "dark" ? theme.palette.secondary2.dark : theme.palette.secondary2.light,
  }

  const styleSvgPathSec = {
    fill: theme.palette.secondary.dark,
    stroke: theme.palette.primary.dark,
  }
  const sepFillColor = theme.palette.primary2.dark
  const sepFillColorContrastText = theme.palette.primary2.contrastText

  return (
    <Box
      sx={styleSxWrap}
      key="web-main"
    >

      <Grid container
        maxWidth="md"
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={6} md={4}
          sx={{
            height: { xs: "100px", sm: "150px", md: "200px" },
          }}
        >
          <Suspense>
            <LazyImageWithMaskAnimation srcImg={srcImgWeb1} />
          </Suspense>

        </Grid>
        <Grid item xs={12} sm={6} md={8}
          sx={{
            height: { xs: "100px", sm: "150px", md: "200px" },
          }}>

          <Suspense>
            <LazyImageWithClipPathAnimations clipPathId="helloClip" srcImg={srcImgWeb4} clipPathFrom={hello_b_svgPathsDReversed[ 0 ]} clipPathTo={[ ...hello_b_svgPathsDReversed ]} imgNamedStyle="fullImgWidthInFullPageWidthAndFullImgHeightDeformed" imgLoading="eager" styleWrap={{ width: "400px", height: "100%" }} />
          </Suspense>


        </Grid>
      </Grid>

      <AnimateScrollIntoViewport
        name={`BL-mainUp-MozteSiNechat-${breakpoint}`}
        key={`BL-mainUp-MozteSiNechat-${breakpoint}`}
        animateFramer={{
          x: 0,
          opacity: 1
        }}
        exitFramer={{ x: -200, opacity: 0 }}
        styleToMerge={{ x: -200, opacity: 0 }}
      >
        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="MozteSiNechat"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "150px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container
            maxWidth={false}
            className="contFeat container"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset',
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
          >
            <Container maxWidth="md">
              <Grid container spacing={2} sx={{ alignItems: 'center' }} >
                <Grid item xs={12} md={8}>
                  <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', backgroundColor: "primary.main", color: "primary.contrastText" }}>
                    <CardContent>
                      <BowListLazy
                        name="BL-mainUp-MozteSiNechat"
                        options={{
                          tick: 2,
                          ...bLOptionsSingle,
                          ...bLOptionsL,
                          styleList: {
                            ...styleBLBordersBLTR50px,
                            fontSize: fontSizeSingle
                          },
                          listPaddingTopPx,
                          listPaddingBottomPx,
                          listMarginTopPx,
                          listMarginBottomPx,
                        }}
                        key="BL-mainUp-MozteSiNechat"
                      >
                        {[ "Nechajte si vytvoriť webstránky u nás.",
                          "Webstránky pre firmy, webstránky pre jednotlivcov",
                          "Modernými, robustnými, a neustále vylepšovanými technológiami.||... a vaše webstránky ostanú moderné aj o 10 rokov.",
                          "Žiadny problém s neskoršími úpravami web stránok alebo doplneniami.||Aj po rokoch vám budeme radi realizovať prípadné ad hoc požadavky my, ale s aktualizáciou web stránok nebudete mať problém ani u iných firiem.",
                          "Webstránky podľa vás.",
                          "Webstránky naozaj iné, s integrovanými vašimi nápadmi, podľa vašich predstáv.",
                          "A stále vám ostane veľa flexibility pre ďalšie a ďalšie zmeny.",
                          "Pridajte sa tiež."
                        ]}
                      </BowListLazy>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    alignItems: 'center'
                  }}
                >
                  <Suspense>
                    <LazyImageWithCircleAndCirclesAroundClipPathAnimation srcImg={srcImgWeb3} widthWrap="100%" />
                  </Suspense>
                </Grid>
              </Grid>

            </Container>
          </Container>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>

      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="triangleUpDownL033R066"
      />
      <SeparatorAbsSvg2Lazy
        name="sepNormalRel2FullHorizontalHills"
        id="gridZiskateKvalitne"
        passedControllingInViewport={false}
        optionsSep={{
          styleWrapSep: {
            lineHeight: "inherit"
          },
          styleSvg: {
            height: "100px",
            transform: 'rotateY(180deg)'
          },
          svgYPadding: 0,
          isRelative: true,
        }} >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
            color: sepFillColorContrastText
          }}
        >
          <Grid container
            maxWidth="lg"
            spacing={1}
            className="gridMain"
          >
            <Grid xs={12} md={6} item
              className="columnLeft"
              style={{ paddingLeft: "0px" }}
            >
              <AnimateScrollIntoViewport
                name={`BL-mainUp-ZiskateKvalitne-${breakpoint}`}
                key={`BL-mainUp-ZiskateKvalitne-${breakpoint}`}
                animateFramer={{
                  x: 0,
                  opacity: 1
                }}
                exitFramer={{ x: -200, opacity: 0 }}
                styleToMerge={{ x: -200, opacity: 0 }}
              >
                <>
                  <BowListLazy
                    name="BL-mainUp-ZiskateKvalitne"
                    options={{
                      nameStyleList: "secondary",
                      nameStyleItem: "primary",
                      ...bLOptionsDouble,
                      ...bLOptionsUpL,
                      styleList: {
                        marginBottom: 0,
                        borderTopLeftRadius: "50px",
                        fontSize: fontSizeDouble,
                      },
                      styleItem: {
                      },
                      styleItemPaper: { ...styleBLPaperPri },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }}
                    key="BL-mainUp-ZiskateKvalitne"
                  >
                    {[ "Získate kvalitné webstránky s viacerými technickými optimalizáciami.",
                    ]}
                  </BowListLazy>
                  <BowListLazy
                    name="BL-mainUp-VelmiRychle"
                    options={{
                      nameStyleList: "primary2",
                      nameStyleItem: "secondary",
                      tick: 1,
                      ...bLOptionsDouble,
                      ...bLOptionsMidL,
                      styleList: {
                        marginTop: 0,
                        ...styleBLBordersBR20,
                        fontSize: fontSizeDouble
                      },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }}
                    key="BL-mainUp-VelmiRychle"
                  >
                    {[ "Veľmi rýchle webstránky||Veľmi rýchle načítavanie web stránok.",
                      "Veľmi bezpečné webstránky",
                      "Komprimovaný kód",
                      <Typography key="linkPWA">
                        <a href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps" rel="nofollow noopener noreferrer" target="_blank" className="extLink" >
                          Progresívna web aplikácia (PWA) &nbsp;<IconExternalLink />
                        </a>
                      </Typography>,
                      "Webstránky optimalizované pre SEO||Vo vyhľadávačoch ako Google alebo Bing môžte dosiahnuť vysokú pozíciu",
                      "Veľké možnosti pre integrovanie dát z rôznych zdrojov",
                      "Webstránky s veľkou flexibilitou pre logiku prezentácie dát.",
                    ]}
                  </BowListLazy>
                </>
              </AnimateScrollIntoViewport>

            </Grid>
            <Grid xs={12} md={6} item
              className="columnRight"
              style={{ paddingRight: "0px" }}
            >
              <AnimateScrollIntoViewport
                name={`BL-mainUp-PreKvalitne-${breakpoint}`}
                key={`BL-mainUp-PreKvalitne-${breakpoint}`}

                animateFramer={{
                  x: 0,
                  opacity: 1
                }}
                exitFramer={{ x: 200, opacity: 0 }}
                styleToMerge={{ x: 200, opacity: 0 }}
              >
                <>
                  <BowListLazy
                    name="BL-mainUp-PreKvalitne"
                    options={{
                      nameStyleList: "primary2",
                      nameStyleItem: "secondary",
                      ...bLOptionsDouble,
                      ...bLOptionsUpR,
                      styleList: {
                        marginBottom: 0,
                        borderTopRightRadius: "50px",
                        fontSize: fontSizeDouble
                      },
                      styleItem: {
                      },
                      styleItemPaper: { ...styleBLPaperSec },
                      styleSvgPath: { ...styleSvgPathSec },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }}
                    key="BL-mainUp-PreKvalitne"
                  >
                    {[ "Pre kvalitné webstránky pridávame"
                    ]}
                  </BowListLazy>
                  <BowListLazy
                    name="BL-mainUp-ZvolenieVhodnej"
                    options={{
                      nameStyleList: "secondary",
                      nameStyleItem: "primary",
                      tick: 2,
                      ...bLOptionsDouble,
                      ...bLOptionsMidR,
                      styleList: {
                        marginTop: 0,
                        ...styleBLBordersBL20,
                        fontSize: fontSizeDouble
                      },
                      listPaddingTopPx,
                      listPaddingBottomPx,
                      listMarginTopPx,
                      listMarginBottomPx,
                    }} key="BL-mainUp-ZvolenieVhodnej">
                    {[ "Zvolenie vhodnej formy vašich web stránok.||Či sú pre vás lepšie čisto statické webstránky alebo webstránky s CMS.",
                      "Zvládnutie možností technológií.|Napr. pre rýchle načítavanie web stránok aj pri množstve obrázkov príp. videa.",
                      "Pochopenie ako SEO funguje.|Ako sa dá naozaj dosiahnuť vysoká pozícia vo vyhľadávačoch na internete.",
                      "Nachystáme vhodný spôsob pridávania ďalších stránok, príp. ďalších obrázkov do galérie.",
                      "Super ak sa vám páči nejaká stránka na internete a vaše webstránky by sa jej mali nejako podobať.||Veľmi subjektívna vec. Chceme, aby ste nám poradili.",
                      "Zvolíme vhodný webhosting pre optimalizáciu nákladov a výkonu.",
                      "Výhodná cena za webstránky.||Cena - veľkosť, zložitosť, potrebný čas. Ale kľudne rátajte, že máme enormný záujem dohodnúť sa. Cena za vytvorenie prevažne malých statických web stránok teraz môže byť aj iba 200 €. Bez kompromisu na kompletnosť a kvalitu. Ale cena závisí vždy od rozsahu a očakávaných funkčností na webstránkach.",
                      "Ústretovosť.|Vaša spokojnosť je dôležitá aj pre nás.",
                      "Kvalitné webstránky||Ak dodatočne nejaké nedostatky nájdeme, tak ich s vami prediskutujeme a opravíme."
                    ]}
                  </BowListLazy>
                </>
              </AnimateScrollIntoViewport>
            </Grid>
          </Grid>
        </div>
      </SeparatorAbsSvg2Lazy>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="trianglesToLeft066Right033"
      />
      <Box color="info" sx={{
        textAlign: 'center',
        padding: 2,
        "& .extLink": {
          color: "unset",
        },

      }}>
        <Paper elevation={3} sx={{
          p: [ 1, 2, 3, 4 ],
          color: "primary.contrastText",
          bgcolor: "primary.main",
          position: "relative",
        }}
          ref={refOverflow}
        >
          <SvgInParentPaddingsLazy refParent={refOverflow} />
          <Typography sx={sxTypography}>
            Najpoužívanejšie technológie na tvorbu web stránok. Podľa Stack Overflow Survey 2023.
          </Typography>
          <ButtonForExternalLink href="https://survey.stackoverflow.co/2023/#most-popular-technologies-webframe-prof" title="Stack Overflow Survey 2023" />
        </Paper>
      </Box>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="triangleDownDeformedL066R033"
      />
      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
        name="scrollNeededFromOwnerForWebPages"
        key="scrollNeededFromOwnerForWebPages"
      >

        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="sepNeededFromOwnerForWebPages"
          key="sepNeededFromOwnerForWebPages"
          optionsSep={{
            // dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit",
              // height: "auto" 
            },
            styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
            svgYPadding: 0,
            isRelative: true,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
          >
            <Container maxWidth="xl" className='cont'>
              <FrameMaskForDiv options={{
                frames: [ "right", "bottom", "left" ],
                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
              }} >
                <NeededFromOwnerForWebPagesLazy />
              </FrameMaskForDiv>
            </Container>
          </div>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="holeL033P066"
      />
      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
        name="scrollReactVsWordpress"
        key="scrollReactVsWordpress"
      >

        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="sepReactVsWordpress"
          key="sepReactVsWordpress"
          optionsSep={{
            // dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit",
              // height: "auto" 
            },
            styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
            svgYPadding: 0,
            isRelative: true,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
          >
            <Container maxWidth="xl" className='cont'>
              <FrameMaskForDiv options={{
                frames: [ "right", "bottom", "left" ],
                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
              }} >
                <ReactVsWordpressLazy />
              </FrameMaskForDiv>
            </Container>
          </div>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="triangle3AlternatingDiminishingHalfL033P066"
      />
      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, }}
        name="roul"
      >
        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="LazyRouletteWheel"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "100px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <div
            className="wrapRoulette"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
          >
            <Suspense
            // fallback="...loading LazyRouletteWheel"
            >
              <LazyRouletteWheel />
            </Suspense>
          </div>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="triangle1DiminishingL066R033"
      />

      <Container maxWidth="none"
        sx={{
          textAlign: 'center', position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
          color: sepFillColorContrastText
        }}
      >
        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="SamiPosudte"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "120px",
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container>
            <AnimateScrollIntoViewport
              name="posudte"
              durationIn={0.5}
              durationOut={0.2}
              animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
              exitFramer={{ clipPath: POLYGON_STAR, }}
              styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
            >
              <Paper
                className="paper"
                sx={{
                  p: [ 1, 2, 3, 4 ],
                  color: "primary.contrastText",
                  backgroundColor: "primary.main",
                }}
              >
                <Typography sx={sxTypography}    >
                  Pozrite sami, akú vysokú pozíciu pri vyhľadávaní na internete môžte dosiahnuť.
                </Typography>
                <Typography sx={sxTypography}>
                  Zadajte v &nbsp;
                  <a href="https://www.google.sk/" rel="nofollow noopener noreferrer" target="_blank"
                    className="extLink"
                  >
                    Google search &nbsp;<IconExternalLink />
                  </a>
                  &nbsp;
                  "kamerové systémy Holíč" alebo "alarmy holic" alebo "videovrátnik Senica".
                </Typography>
                <Typography sx={sxTypography}>
                  Nájdete na prvej strane po webstránkach s platenou reklamou aj nami vytvorené webstránky pc123.sk.
                </Typography>
                <Typography sx={sxTypography}>
                  <a href="https://pc123.sk" rel="nofollow noopener noreferrer" target="_blank" key="linkPc123"
                    className="extLink"
                  >
                    pc123.sk
                  </a>
                </Typography>
                <Typography sx={sxTypography}>
                  <a href="https://kriz.epocha.sk" rel="nofollow noopener noreferrer" target="_blank" key="linkkriz" className="extLink"  >
                    kriz.epocha.sk &nbsp;<IconExternalLink />
                  </a>
                </Typography>
                <Typography sx={sxTypography}>
                  <a href="https://pam.epocha.sk" rel="nofollow noopener noreferrer" target="_blank" key="linkpam" className="extLink" >
                    pam.epocha.sk &nbsp;<IconExternalLink />
                  </a>
                </Typography>
              </Paper>
            </AnimateScrollIntoViewport>
          </Container>
        </SeparatorAbsSvg2Lazy>
      </Container>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="xFallingL066R033"
      />
      <div
        style={{
          transform: "translateZ(0)",
          // minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        id="wordpress"
        // ref={refWordpress}
        key="wordpressTextProblem"
      >

        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="IntroTextWordpressProblemCCh"
          passedControllingInViewport={false}
          key="IntroTextWordpressProblemCCh"
          optionsSep={{
            dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "100px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <div
            style={{
              position: "relative",
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText,
            }}
          >
            <WavedFrameAnimationForChildren
              nameWavedFrames="baseWavedFramesT2R6B2L4"
              // {...optionsWavedFrameAnimationForChildren}
              namePaddings="T20-R5-B20-L10"
              key="wavedFr Tvorba-web-stranok"
            >
              <Container maxWidth="xl" className='cont'>
                <FrameMaskForDiv
                  options={{
                    frames: [ "right", "bottom", "left" ],
                    masks: { top: "mask10", right: "mask10V", bottom: "mask10", left: "mask10V" },
                    rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false }
                  }}
                >
                  <AnimateScrollIntoViewport
                    durationIn={0.5}
                    durationOut={0.2}
                    animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, backgroundColor: alpha(bgPrimaryDark, 0), }}
                    exitFramer={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.2), }}
                    styleToMerge={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.2), }}
                    name="maskAngBgWordpress"
                  // refParent={refWordpress}
                  >
                    <Suspense
                    // fallback={<div>Loading Sep WavedFrame FrameMask AnimRef ...</div>}
                    >
                      <LazyIntroTextWordpressProblemCCh />
                    </Suspense>
                  </AnimateScrollIntoViewport>
                </FrameMaskForDiv>
              </Container>
            </WavedFrameAnimationForChildren>
          </div>
        </SeparatorAbsSvg2Lazy>
      </div>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="hillDiminishingL066R033"
      />
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="lSquare10DiminishingRSquare5L033R066"
      />


      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
        name="scrollReactForOwner"
        key="scrollReactForOwner"
      >

        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="sepReactForOwner"
          key="sepReactForOwner"
          optionsSep={{
            // dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit",
              // height: "auto" 
            },
            styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
            svgYPadding: 0,
            isRelative: true,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText,

            }}
          >
            <Container maxWidth="xl" className='cont'>
              <FrameMaskForDiv options={{
                frames: [ "right", "bottom", "left" ],
                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
              }} >
                <Suspense
                  // fallback={<div>Loading AnimScroll SeparatorAbsSvg2 Frame ...</div>}
                  key="susReactForOwner">
                  <LazyReactForOwner />
                </Suspense>
              </FrameMaskForDiv>
            </Container>
          </div>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="whaleL066R033"
      />


      <AnimateScrollIntoViewport
        durationIn={0.5}
        durationOut={0.2}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, overflow: "inherit" }}
        name="scrollReactForVisitors"
        key="scrollReactForVisitors"
      >

        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="sepReactForVisitors"
          key="sepReactForVisitors"
          optionsSep={{
            // dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit",
              // height: "auto" 
            },
            styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
            svgYPadding: 0,
            isRelative: true,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: sepFillColor,
              color: sepFillColorContrastText,
            }}
          >
            <Container maxWidth="xl" className='cont'>
              <FrameMaskForDiv options={{
                frames: [ "right", "bottom", "left" ],
                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
              }} >
                <Suspense
                  //  fallback={<div>Loading AnimScroll SeparatorAbsSvg2 Frame ...</div>}
                  key="susReactForVisitors">
                  <LazyReactForVisitors />
                </Suspense>
              </FrameMaskForDiv>
            </Container>
          </div>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="whaleTriangleL033R066"
      />

      <AnimateScrollIntoViewport
        durationIn={1}
        durationOut={0.7}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, }}
        name="CirclesMotionX4PopularWebsites"
      >
        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="CirclesMotionX4PopularWebsites"
          optionsSep={{
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "100px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container
            maxWidth={false}
            className="container contFeat"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}
            key="contFeat"
          >
            <Container
              className="contVitSep"
              sx={{
                paddingX: [ 0, 1, 2, 3, 4 ]
              }}
            >
              <Box>
                <Typography
                  component={"h4"}
                  sx={{
                    px: [ 1 ],
                    textAlign: 'center',
                    // fontSize: [ "1rem", "1.25rem", "1.5rem" ],
                    fontSize: [ "small", "medium", "large", "larger" ],
                  }}
                >
                  Najpopulárnejšie webstránky vo svete a na Slovensku
                </Typography>
                <Suspense>
                  <LazyCirclesMotionX4PopularWebsites />
                </Suspense>
              </Box>
            </Container>

          </Container>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="gooseTriangleL066R033"
      />

      <AnimateScrollIntoViewport
        name="CirclesMotionX4WithCssWebsites"
        durationIn={1}
        durationOut={0.7}
        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
        exitFramer={{ clipPath: POLYGON_STAR, }}
        styleToMerge={{ clipPath: POLYGON_STAR, }}
      >
        <SeparatorAbsSvg2Lazy
          name="sepNormalRel2FullHorizontalHills"
          id="CirclesMotionX4WithCssWebsites"
          optionsSep={{
            dPathOrderNotIn: [ 0 ],
            styleWrapSep: {
              lineHeight: "inherit"
            },
            styleSvg: {
              height: "100px",
              transform: 'rotateY(180deg)'
            },
            svgYPadding: 0,
            isRelative: true,
          }} >
          <Container maxWidth="none" className="contCss container"
            style={{
              position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
              color: sepFillColorContrastText
            }}>
            <Container
              sx={{
                paddingX: [ 0, 1, 2, 3, 4 ]
              }}
            >
              <Box>
                <Typography
                  component={"h4"}
                  sx={{
                    px: [ 1 ],
                    textAlign: 'center',
                    fontSize: [ "small", "medium", "large", "larger" ],
                  }}
                >
                  Najlepšie webstránky o CSS
                </Typography>
                <Suspense>
                  <LazyCirclesMotionX4WithCssWebsites />
                </Suspense>
              </Box>
            </Container>
          </Container>
        </SeparatorAbsSvg2Lazy>
      </AnimateScrollIntoViewport>
      <SeparatorFromLeftHalfAndFromRightHalfLazy
        optionsName="swallowL066R033"
      />
    </Box>
  );
}

export default MainUp




