import React, { lazy, Suspense } from 'react';

const SvgInParentPaddings = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'SvgInParentPaddingsLazy' */ './SvgInParentPaddings'));

function SvgInParentPaddingsLazy(props) {
    return (
        <div>
            <Suspense>
                <SvgInParentPaddings {...props} />
            </Suspense>
        </div>
    );
}

export default SvgInParentPaddingsLazy;