import * as React from 'react';
import Button from '@mui/material/Button';

function ButtonForExternalLink({ href = "", title = "", color = "primary" }) {
    return (
        <Button href={href} target="_blank" rel="noopener noreferrer" color={color} variant="contained">
            {title}
        </Button>
    );
}

export default ButtonForExternalLink;