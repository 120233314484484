import React, { Suspense, lazy } from 'react'

const SeparatorFromLeftHalfAndFromRightHalf = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'SeparatorFromLeftHalfAndFromRightHalfLazy' */ './SeparatorFromLeftHalfAndFromRightHalf'))
const SeparatorFromLeftHalfAndFromRightHalfLazy = (props) => {
    return (
        <Suspense>
            <SeparatorFromLeftHalfAndFromRightHalf {...props} />
        </Suspense>
    )
}

export default SeparatorFromLeftHalfAndFromRightHalfLazy